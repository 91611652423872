import { useActiveUser } from '~/stores/activeUser'

export default defineNuxtRouteMiddleware(async (to) => {
  if (to.meta.public) {
    return
  }

  const activeUser = useActiveUser()
  const { permissions } = activeUser

  const { $auth0, $auth0Ready, $datadog } = useNuxtApp()
  const { isAuthenticated, checkSession } = $auth0()

  await $auth0Ready()

  if (isAuthenticated.value) {
    return
  }

  if (!process.client) return

  try {
    await checkSession()
  } catch (error) {
    $datadog.addAction('redirect_to_sign_in', { error })
    return navigateTo('/auth/login')
  }

  if (permissions.includes(PERMISSIONS.writeTeamContent)) return

  $datadog.addAction('Access admin - DENIED')

  return navigateTo('/auth/login')
})
